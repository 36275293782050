@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */


@media (max-width: 480px) {
  .ant-calendar-range {
      width: 320px;
  }
  .ant-calendar-range-part {
      width: 100%;
  }
}

@media (max-width: 992px) {
  /* .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  } */

  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
}

/* button {
  background-color: aquamarine !important;
} */
.ant-btn-primary {
  background-color: #1677ff;
}


.file-selector{
  border: 2px transparent solid;
}

.file-selector:hover{
  border: 2px whitesmoke solid;
}

.btn-zoom{
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
}
.btn-action{
  height: 40px;
  background-color: transparent;
  border: 0;
}

.position-relative {
  position: relative;
}

.dangerText {
  color: #ff4d4f;
}
.selected-card.danger .ant-card-head{
  background-color: #ff4d4f;
  color: white;
}

.selected-card.success .ant-card-head{
  background-color: #1677ff;
  color: white;
}
.ant-switch {
  background-color: grey;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ant-modal-content:has(> .ant-modal-body > .ant-card) {
  padding: 0.5rem;
 }

.card-create-suggestion .ant-card-body {
  padding: 0.5rem;
}